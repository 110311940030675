<section>
  <div class="ta-c">
    <h3>Selecciona sector(es)</h3>
    <p class="mt-16 mb-24">
      ¿Que unidades de negocio Contaran con Cliente-Recoge?</p>
  </div>
  <div>
    <mat-card class="d-flex jc-sb">
      <div class="ta-c size-22">
        <img src="assets/images/credentials/core.svg">
        <p>
          Core
        </p>
        <input class="form-check-input" type="checkbox" [checked]="core[0].available" (change)="changeCheck(core[0],core[0].available)">
      </div>
      <div class="ta-c size-22">
        <img src="assets/images/credentials/intradevco.svg">
        <p>
          Intradevco
        </p>
        <input class="form-check-input" type="checkbox" [checked]="intradevco[0].available" (change)="changeCheck(intradevco[0],intradevco[0].available)">
      </div>
      <div class="ta-c size-22">
        <img src="assets/images/credentials/soluciones.svg">
        <p>
          Soluciones
        </p>
        <input class="form-check-input" type="checkbox" [checked]="soluciones[0].available" (change)="changeCheck(soluciones[0],soluciones[0].available)">
      </div>
      <div class="ta-c size-22">
        <img src="assets/images/credentials/value.svg">
        <p>
          Value
        </p>
        <input class="form-check-input" type="checkbox" [checked]="value[0].available" (change)="changeCheck(value[0],value[0].available)">
      </div>
    </mat-card>
    <div class="d-flex jc-center mt-32 mb-16">
      <div>
        <button class="btn btn-primary ph-32 mr-16" type="button" (click)="Continuar()">Continuar</button>
      </div>
      <div>
        <button class="btn btn-gray ph-32" type="button" (click)="cancelar()">Cancelar</button>
      </div>
    </div>
  </div>
</section>