import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
export interface errors {
  status: string;
  sku: string;
  description: string;
}
@Component({
  selector: 'app-error-alert',
  templateUrl: './error-alert.component.html',
  styleUrls: ['./error-alert.component.scss']
})

export class ErrorAlertComponent implements OnInit {

  dataRecieved: any;
  modals =
    [{
      img: 'assets/images/modals/icon-file-success.svg',
      title: 'Archivo subido con éxito',
      description: 'Tu archivo a sido subido de forma exitosa, ahora solo un paso más para confirmar tu pedido.',
      actions: [{
        name: 'ENTENDIDO',
        class: 'btn-green ',
        event: () => this.save(1, "success")
      }],
    },
    {
      img: 'assets/images/modals/icon-alert-new.svg',
      title: 'Proceso fallido',
      description: 'La columna CANTIDAD está vacía.',
      actions: [{
        name: 'ENTENDIDO',
        class: 'btn-green',
        event: () => this.save(2, "alert")
      }],
    },
    {
      img: 'assets/images/modals/icon-alert-new.svg',
      title: 'No se procesó todo el pedido',
      description: `Hemos encontrado la(s) siguiente(s) observacion(es) en el archivo, puedes volver a cargarlo para hacer la corrección.`,
      actions: [{
        name: 'CANCELAR',
        class: 'btn-back-new fts-16 font-medium',
        event: () => this.save(2, "alert")
      },
      {
        name: 'CONTINUAR',
        class: 'btn-green',
        event: () => this.save(1, "success")
      }],
    },
    {
      img: 'assets/images/modals/icon-alert-new.svg',
      title: 'Formato de archivo no admitido',
      description: `Lo sentimos por el momento solo se permiten archivos en formato <b style="font-family: 'AlicorpWeb-Bold'">Excel (*.xls, *.xlsx)</b>.`,
      actions: [{
        name: 'ENTENDIDO',
        class: 'btn-green',
        event: () => this.save(1, "success")
      }],
    },
    {
      img: 'assets/images/modals/icon-file-success.svg',
      title: 'Archivo subido con éxito',
      description: 'Tu archivo ha sido subido de forma exitosa, ahora solo un paso más para confirmar tu pedido.',
      actions: [{
        name: 'ENTENDIDO',
        class: 'btn-green font-medium',
        event: () => this.save(1, "success")
      }],
    },
    {
      img: 'assets/images/modals/loader-mode-pay.svg',
      title: 'Un momento por favor...',
      description: 'Estamos actualizando el monto total, esto puede tardar varios segundos.',
    },
    {
      img: 'assets/images/modals/loader-upload.svg',
      title: 'Subiendo archivo',
      description: 'Por favor espera unos segundos mientras realizamos la carga de tu archivo.',
    },
    {
      img: 'assets/images/modals/icon-ops.png',
      title: 'Oops',
      description: 'Algo salió mal!',
      actions: [{
        name: 'OK',
        class: 'btn-green font-medium',
        event: () => this.save(1, "success")
      }],
    },
    {
      img: 'assets/images/modals/icon-check.svg',
      title: 'Verificación exitosa',
      actions: [{
        name: 'ENTENDIDO',
        class: 'btn-green font-medium opacity',
        event: () => this.save(1, "success")
      }],
    },
    {
      img: 'assets/images/modals/cart-spot.svg',
      title: 'Importe mínimo no válido',
      description: 'El valor del pedido no cubre el pedido mínimo rentable',
      actions: [{
        name: 'Ok, Entendido',
        class: 'btn-green font-medium opacity',
        event: () => this.save(1, "success")
      }],
    },
    {
      img: 'assets/images/modals/loader-upload.svg',
      title: 'Un momento por favor...',
      description: 'Estamos actualizando el monto total, esto puede tardar varios segundos.',
    },
    {
      img: 'assets/images/modals/icon-price.svg',
      title: 'Actualización de precios',
      description: 'Vamos a actualizar la lista de precios, es probable que veas ligeros cambios en el valor total.',
      actions: [{
        name: 'Ok, Entendido',
        class: 'btn-green font-medium opacity',
        event: () => this.save(1, "success")
      }],
    },
    {
      img: 'assets/images/modals/icon-alert-new.svg',
      title: 'No se procesó todo el pedido',
      description: `Hemos encontrado la(s) siguiente(s) observacion(es) en el archivo, puedes omitirlas y  <b class="font-bold">+ Subir archivo</b> o  <b class="font-bold">Elegir archivo</b> para hacer la corrección`,
      actions: [{
        name: 'CANCELAR',
        class: 'btn-back-new fts-16 font-medium',
        event: () => this.save(2, "alert")
      },
      {
        name: 'CONTINUAR',
        class: 'btn-green',
        event: () => this.save(1, "success")
      }],
    },
    {
      img: 'assets/images/modals/icon-alert-new.svg',
      title: 'Cabeceras incorrectas',
      description: `Corregir cabeceras y <b class="font-bold">Elegir archivo</b> para hacer la corrección`,

    },
    ]
    dataSource: errors[] = [];
    displayedColumns: string[] = ['status', 'description']; // Iniciamos sin la columna 'sku'
    
    @Input() data!: any;
    @Input() type: string = '';
    @Input() showSku: boolean = true;
  
    constructor(private sanitizer: DomSanitizer) {}
  
    ngOnInit(): void {
      // Actualizamos las columnas mostradas de acuerdo a showSku
      this.updateDisplayedColumns();
  
      this.dataRecieved = this.data;
      if (this.data?.status === 2) {
        this.dataSource = this.data?.skuError;
      }
    }
  
    updateDisplayedColumns() {
      // Si showSku es true, incluimos la columna 'sku', de lo contrario no
      this.displayedColumns = this.showSku
        ? ['status', 'sku', 'description']
        : ['status', 'description'];
    }
  
    transform(html: any) {
      return this.sanitizer.bypassSecurityTrustHtml(html);
    }
  
    save(status: number, message: string) {
      // Acción para guardar
    }
  
    onNoClick() {
      // Acción para cerrar el modal
    }
}
