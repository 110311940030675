import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClienteService } from '../http-cliente.service';

@Injectable({
  providedIn: 'root'
})
export class CredentialsService {

  private url = environment.apiURL;
  constructor(
    private _http: HttpClienteService,
  ) { }

  getSpecialAttributesList(data: any) {
    const query = `${this.url}/wcl-customer/paginate-customer?isPaginate=${data.isPaginate}&query=${data.characterSearch}&page=${data.page}&pageSize=${data.pageSize}`;
    return this._http.get(query);
  }

  getUserList(data: any, customers_user: string) {
    const query = `${this.url}/wcl-customer/${customers_user}?query=${data}`;
    return this._http.get(query);
  }

  getSavePasswordNew(data) {
    const query = `${this.url}/wcl-customer/user-create`;
    return this._http.post(query, data);
  }

  getSavePasswordChange(data) {
    const query = `${this.url}/wcl-authentication/bo-change-password`;
    return this._http.post(query, data);
  }

  getUpdateCustomerSetting(data) {
    const query = `${this.url}/wcl-customer/bo-update-customer-setting`;
    return this._http.post(query, data);
  }

  getUpdatepickup(data) {
    const query = `${this.url}/wcl-customer/bo-update-customer-pickup`;
    return this._http.post(query, data);
  }
}
