import { DecimalPipe } from '@angular/common';
import { Component, OnInit, ViewChildren, QueryList, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  SpecialAttributes,
  UserList,
} from '@core/interfaces/credentials.model';
import { CredentialsService } from '@core/services/credentials/credentials.service';
import { NgbdSortableHeader } from '@core/services/sort/sortEvent.directive';
import { CountryService } from '../../../../app/core/services/sort.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { map, Observable, take } from 'rxjs';
import { ModalCredentialsComponent } from '@shared/modals/modal-credentials/modal-credentials.component';

@Component({
  selector: 'app-costumer-list',
  templateUrl: './costumer-list.component.html',
  styleUrls: ['./costumer-list.component.scss'],
  providers: [CountryService, DecimalPipe],
})
export class CostumerListComponent implements OnInit {
  @Input() shouldRefresh: boolean = true;

  page = 1;
  pageSize = 10;
  collectionSize: number;
  characterSearch: string = '';
  searchTextValueNewPass = '';
  searchTextValueChangePass = '';
  isChecked = false;
  loading = false;
  counter = Array;
  listSpecialUserShow: Array<SpecialAttributes> = [];
  listSpecialUser: Array<SpecialAttributes> = [];
  errorListSpecialUser = false;
  responseListSpecialUser: UserList;
  typeCheckbox;
  valueSelectCheckbox: boolean;

  @ViewChildren(NgbdSortableHeader) headers!: QueryList<NgbdSortableHeader>;

  constructor(
    public service: CountryService,
    private dialog: MatDialog,
    public _credentialsService: CredentialsService,
    public _utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this.specialAttributesList(this.characterSearch);
  }

  refreshList() {
    this.specialAttributesList(this.characterSearch);
  }

    //LISTAR USUARIOS QUE RESPONDE EL SERVICIO
    specialAttributesList(valueText) {
      this.loading = true;
      this.listSpecialUserShow = [];
      this.listSpecialUser = [];
      let dataCredentials = {
        // MEJORA PARA DEJAR USAR EL REPLACE
        // characterSearch: encodeURIComponent(valueText),
        characterSearch: valueText?.replace(/&/g, '%26').replace(/#/g, '%23'),
        page: this.page,
        pageSize: this.pageSize,
        isPaginate: true
      };
      this._credentialsService
        .getSpecialAttributesList(dataCredentials)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            this.errorListSpecialUser = false;
            this.loading = false;
            this.responseListSpecialUser = res;
            this.listSpecialUser = res?.data.map((element: any) => {
              let temp: any = {
                ...element,
                pickUpStatus: element.pickUp.find((a) => a.available === true)
                  ?.available
                  ? true
                  : false,
              };
              return temp;
            });
            this.sliceCountries();
          },
          error: (err) => {
            this.loading = false;
            this.errorListSpecialUser = true;
            return;
          },
        });
    }

    //LISTAR USUARIOS INICIALES
  refreshCountries() {
    this.specialAttributesList(this.characterSearch);
  }

  sliceCountries() {
    this.listSpecialUserShow = this.listSpecialUser;
    this.collectionSize = this.responseListSpecialUser.totalCount;
  }
  //FILTRAR POR BUSQUEDA
  searchList(value: string): void {
    if ((value && value.length >= 3) || value.length === 0) {
      this.specialAttributesList(value);
    } else {
      this.listSpecialUserShow = this.listSpecialUser;
      this.collectionSize = this.responseListSpecialUser.totalCount;
    }
  }

  onChangeCheckbox(valuecheckbox: any, type: string, i, country) {
    switch (type) {
      case 'ally':
        this.listSpecialUserShow[i].ally = !valuecheckbox;
        this.typeCheckbox = 'aliados';
        this.valueSelectCheckbox = this.listSpecialUserShow[i].ally;
        break;
      case 'benefit':
        this.listSpecialUserShow[i].benefit = !valuecheckbox;
        this.typeCheckbox = 'reportes';
        this.valueSelectCheckbox = this.listSpecialUserShow[i].benefit;
        break;
      case 'advance':
        this.listSpecialUserShow[i].advance = !valuecheckbox;
        this.typeCheckbox = 'avances';
        this.valueSelectCheckbox = this.listSpecialUserShow[i].advance;
        break;
    }
    let dataBody = {
      code: country.code,
      field: this.typeCheckbox,
      value: this.valueSelectCheckbox,
    };
    this._credentialsService
      .getUpdateCustomerSetting(dataBody)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this._utilsService.snackBarUpload(
            'Genial! Usuario se actualizó correctamente',
            'save'
          );
        },
        error: (err) => {
          this._utilsService.snackBarUpload(
            `Error!. Intenta guardar nuevamente`,
            'warning-l'
          );
          return;
        },
      });
  }
  //PAGINADOR
  onSort({ column, direction }: any) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  //MODAL DE ACTIVACIÓN DE REPORTES
  onChange(value: any, codeSelect: string, slide: any, pickUpStatus: boolean) {
    slide.checked = pickUpStatus;
    value.codeSelect = codeSelect;
    const dialogRef = this.dialog.open(ModalCredentialsComponent, {
      width: '35rem',
      data: value,
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if(data){
      this._credentialsService
        .getUpdatepickup(data)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            slide.checked = !pickUpStatus;
            this._utilsService.snackBarUpload(
              'Genial! Usuario se actualizó correctamente',
              'save'
            );
            this.specialAttributesList('');
          },
          error: (err) => {
            slide.checked = pickUpStatus;
            this._utilsService.snackBarUpload(
              `Error!. Intenta guardar nuevamente`,
              'warning-l'
            );
            return;
          },
        });
      }
    });
  }

}
