import { Injectable, Injector } from "@angular/core";
import {
    HttpEvent,
    HttpRequest,
    HttpHandler,
    HttpInterceptor
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@env/environment";
import { AuthService } from '../auth/auth.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    private token = '';
    private ocpKey = environment.ocpKey;
    constructor(
        private inj: Injector,
        private _authService: AuthService
        ) {
            this.token = this._authService.getToken()
         }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (req.headers.has('Authorization')) {
            return next.handle(req);
        }

        const tok = this.inj.get(AuthService);

        const authHeader = tok.getToken();

        if (!tok.isLoggedIn()) {
            const dupReq = req.clone({
                headers: req.headers
                    .set('Content-Type', 'application/json; charset=utf-8')
                    .set('Ocp-Apim-Subscription-Key', this.ocpKey)
            });
            return next.handle(dupReq);
        } else {
            const dupReq = req.clone({
                headers: req.headers
                    .set('Content-Type', 'application/json; charset=utf-8')
                    .set('Ocp-Apim-Subscription-Key', this.ocpKey)
                    .set('Authorization', `Bearer ${authHeader}`)
            });
            return next.handle(dupReq);
        }
    }
}
