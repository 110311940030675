import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClienteService } from '../http-cliente.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UploadReportsService {
  private url = environment.apiURL;
  constructor(private _http: HttpClienteService, private http: HttpClient) {}

  getReports(name: string) {
    const query = `${this.url}/wcl-customer/reports-path?path=${name}`;
    return this._http.get(query);
  }
  loadReports(data: any) {
    const query = `${this.url}/wcl-customer/upload-report`;
    return this._http.post(query, data);
  }
  createPresigned(data: any) {
    const query = `${this.url}/wcl-customer/create-presigned-url`;
    return this._http.post(query, data);
  }
  uploadFileToBlob(preSignedUrl: string, file: File): Observable<any> {
    const headers = new HttpHeaders({
      'x-ms-blob-type': 'BlockBlob',
      'Content-Type': file.type || 'application/octet-stream', // Asegura que tenga Content-Type
    });

    console.log('Uploading to:', preSignedUrl);
    console.log('Uploading file:', file.name, 'Size:', file.size);

    return this.http.put(preSignedUrl, file, {
      headers,
      observe: 'response',
    });
  }

  getGeneralReports(type: number) {
    const param = type === 1 ? 'recognition-list' : 'sales-progress-list';
    const query = `${this.url}/wcl-document/${param}`;
    return this._http.get(query);
  }
  deleteGeneralReports(data: any, type: number) {
    const param = type === 1 ? 'recognition-delete' : 'sales-progress-delete';
    const query = `${this.url}/wcl-document/${param}`;
    return this._http.delete(query, { body: data });
  }
  loadGeneralReports(data: any, type: number) {
    const param = type === 1 ? 'upload-recognitions' : 'upload-sales-progress';
    const query = `${this.url}/wcl-document/${param}`;
    return this._http.post(query, data);
  }
  getSalesProgressLogs(id: number) {
    const query = `${this.url}/wcl-document/file-versions?id=${id}`;
    return this._http.get(query);
  }
}
