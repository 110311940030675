<div class="d-flex jc-center">
  <div class="container-modal" [ngClass]="dataRecieved.status == 2 ? 'max-size':''">
    <div class="img-modal">
      <img [ngClass]="dataRecieved.status == 8 ? 'check' :''" [src]="modals[dataRecieved.status]?.img" alt="">
    </div>
    <div class="message">
      <h1>{{modals[dataRecieved.status]?.title}}</h1>
      <p [innerHTML]="modals[dataRecieved.status]?.description"></p>
    </div>
    <div *ngIf="dataRecieved.status == 2" class="aditional-info">
      <div class="table-responsive">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-alert-modal">
          
          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="col-1">Estado</th>
            <td mat-cell *matCellDef="let element" class="red-alert">
              <p [ngClass]="element.error?.type != 6 ? '' :'circle' ">
                {{element.error?.type != 6 ? 'ERROR LÍNEA ' + element.position : 'NO DISPONIBLE'}}
              </p>
            </td>
          </ng-container>

          <!-- SKU Column (Dinamically Shown) -->
          <ng-container *ngIf="showSku" matColumnDef="sku">
            <th mat-header-cell *matHeaderCellDef class="col-2">
              {{ type === 'form-way' ? 'Id form' :(type === 'seller-management' ? 'Vendedor' : 'SKU')}}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ type === 'seller-management' || type === 'form-way'  ? element[1] : element.sku || element[2] }}
            </td>
          </ng-container>

          <!-- Description Column (Dynamically Adjust Width) -->
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef [ngClass]="{'col-3-full': !showSku, 'col-3': showSku}">Descripción</th>
            <td mat-cell *matCellDef="let element" class="text-message" [ngClass]="{'col-3-full': !showSku, 'col-3': showSku}">
              <p [innerHTML]="element.error?.type != 6 ? transform(element.error?.message) : element.description"></p>
            </td>
          </ng-container>

          <!-- Table Header Row and Data Rows -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
    <div *ngIf="dataRecieved.status == 12" class="aditional-info">
      <div class="table-responsive">
        <div *ngFor="let element of data.skuError" class="content-table">
          <div class="red-alert">{{'ERROR LÍNEA ' + element.index}}</div>
          <div class="">{{element.sku}}</div>
          <div>{{element.error.message}}</div>
        </div>
      </div>
    </div>
    <div *ngIf="dataRecieved.status == 13" class="aditional-info">
      <div class="table-responsive">
        <div class="">{{data.skuError[0]?.cabecera}}</div>
      </div>
    </div>
  </div>
</div>
