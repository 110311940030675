<div class="modal-container">
  <h5 class="text-center font-weight-bold mb-8">Historial de modificación</h5>
  <div class="table-container" style="max-height: 450px; overflow-y: auto;">
    <table class="table">
      <!-- Encabezados de la tabla -->
      <thead class="">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Archivo</th>
          <th scope="col">Categoría</th>
          <th scope="col">Fecha de modificación</th>
        </tr>
      </thead>
      <tbody>
        <!-- Filas de la tabla -->
        <tr *ngFor="let report of data.value; let i = index">
          <td class="ta-c">{{ i + 1 }}</td>
          <td class="ta-c">{{ report.fileName}}</td>
          <td class="ta-c">{{ report.category}}</td>
          <td class="ta-c">{{ report.date | date: 'dd/MM/yy, h:mm a' }}</td>
        </tr>
        <!-- Loader -->
        <ng-container *ngIf="data.loading">
          <!-- Skeleton loader -->
          <tr *ngFor="let number of counter(3)">
            <td scope="row" class="ta-c td-padding">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                class="mb-0"
              ></ngx-skeleton-loader>
            </td>
            <td class="ta-c td-padding">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
              ></ngx-skeleton-loader>
            </td>
            <td class="ta-c td-padding">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
              ></ngx-skeleton-loader>
            </td>
            <td class="ta-c td-padding">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
              ></ngx-skeleton-loader>
            </td>
          </tr>
        </ng-container>
        <!-- No hay resultados -->
        <ng-container *ngIf="!data.loading && data.value.length === 0">
          <tr>
            <td colspan="4">
              <div class="d-flex justify-content-center p-8 text-muted">
                No hay resultados para mostrar
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-center align-items-center">
    <button cdkFocusInitial class="btn-red btn-primary ph-32"
      (click)="confirm()">Volver</button>
  </div>
</div>
