import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClienteService } from '../http-cliente.service';
import { LocalStorageService } from '../local-storage.service';
import { Observable, Subscriber } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private token:string;
  private url = environment.apiURL;
  private obsIsLoggedIn: Observable<boolean>;
  private subscriberIsLoggedIn: Subscriber<boolean> | undefined;
  user : any;
  
  constructor(
    public router: Router,
    private _http: HttpClienteService,
    private _localStorageService: LocalStorageService
  ) { 
    this.token = this.getToken();
  }

  subscribeIsLoggedIn(): Observable<boolean>{
    return this.obsIsLoggedIn
  }

  pushIsLoggedIn(): void{
    this.subscriberIsLoggedIn && this.subscriberIsLoggedIn.next(this.isLoggedIn());
  }


  
  getToken(): string {
    const token = this.getUser() ?
    (this.getUser().token && this.getUser().token != "" ? this.getUser().token: {} ) : {};
    return token
  }

  getUser() {
    const user = this._localStorageService.getItem('user');
   if(user){
    return Object.keys(user).length !== 0 ? user: null;
   }
  }

  setUser(obj: any) {
    this._localStorageService.setItem('user', obj);
    this.token = this.getToken();
    if(this.token) this.pushIsLoggedIn();
    this.user = this.getUser();
  }
  logout() {
    this._localStorageService.cleanStore();
    this.pushIsLoggedIn();
    this.router.navigate(['/login']);
  }

  login(data: any){
    const query = `${this.url}/wcl-authentication/bo-login`;
    return this._http.post(query, data);
  }
  
  getInformation(){
    const query = `${this.url}/wcl-customer/bo-enum`;
    return this._http.get(query);
  }
  
  isLoggedIn(): boolean {
    const token = this.getToken();
    return Object.keys(token).length !== 0
  }
}
