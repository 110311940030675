import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth/auth.service';
import { take } from 'rxjs';
import { Md5 } from 'ts-md5';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public show: boolean = false;
  public loading: boolean = false;
  public loginForm!: FormGroup;
  public logInError: any = false;

  constructor(
    private _formBLogin: FormBuilder,
    private _authService: AuthService,
    public router: Router,
    ) {
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.loginForm = this._formBLogin.group({
      email: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(50)])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(25)])]
    });
  }
  showPassword() {
    this.show = !this.show;
  }


  // Simple Login
  login() {
    if(this.loginForm.valid){
      this.loading = true;
      const md5 = new Md5()
      let data = {
        email : this.loginForm.controls['email'].value,
        password : md5.appendStr(this.loginForm.controls['password'].value).end()
      }
      this._authService.login(data).pipe(take(1)).subscribe({
        next:(res) => {
          if(res){
            this._authService.setUser(res);
            this.logInError = false;
            this.router.navigate(['/admin/Nuevo-cliente']);
            this.loading = false;
          }else{
            this.loading = false;
            this.logInError = true;
          }
        },
        error:(err)=> {
          this.logInError = true;
          this.loading = false;
          console.log(err)
          return
        }
      })
    }
  }
}
