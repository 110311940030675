import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { elementAt } from 'rxjs';

@Component({
  selector: 'app-modal-credentials',
  templateUrl: './modal-credentials.component.html',
  styleUrls: ['./modal-credentials.component.scss']
})
export class ModalCredentialsComponent implements OnInit {
  dataCheckbox = [
    {
      code: '',
      sector: '0010',
      sectorName: 'Core',
      available: false
    },
    {
      code: '',
      sector: '0007',
      sectorName: 'Intradevco',
      available: false
    },
    {
      code: '',
      sector: '0012',
      sectorName: 'Soluciones',
      available: false
    },
    {
      code: '',
      sector: '0070',
      sectorName: 'Value',
      available: false
    },

  ];
  core
  intradevco
  soluciones
  value
  dataChange = []


  constructor(public dialogRef: MatDialogRef<ModalCredentialsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.dataInitial();
  }

  dataInitial() {
    this.dataCheckbox = this.data.concat(this.dataCheckbox).filter((data, index, j) =>
      index === j.findIndex((t) => (t.sectorName === data.sectorName))
    );
    this.core = this.dataCheckbox.filter(element => element.sectorName === 'Core');
    this.intradevco = this.dataCheckbox.filter(element => element.sectorName === 'Intradevco');
    this.soluciones = this.dataCheckbox.filter(element => element.sectorName === 'Soluciones');
    this.value = this.dataCheckbox.filter(element => element.sectorName === 'Value');

    this.dataEnd();
  }
  changeCheck(data: any, check: boolean) {
    let dataSubir = []
    switch (data.sectorName) {
      case 'Core':
        data.available = !check;
        break;
      case 'Intradevco':
        data.available = !check;
        break;
      case 'Soluciones':
        data.available = !check;
        break;
      case 'Value':
        data.available = !check;
        break;
    }
    dataSubir.push(data)
    this.dataEnd();
  }
  dataEnd() {
    let valueConcat = []
    this.dataChange = valueConcat.concat(this.core, this.intradevco, this.soluciones, this.value);
    this.dataChange.forEach(element => {
      element.code = this.data.codeSelect;
    })
  }
  cancelar() {
    this.dialogRef.close();
  }
  Continuar() {
    this.dialogRef.close(this.dataChange);
  }
}
