import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-logs-modal',
  templateUrl: './logs-modal.component.html',
  styleUrls: ['./logs-modal.component.scss']
})
export class LogsModalComponent implements OnInit {

  public data: any; // Definir data como cualquier tipo de objeto
  counter = Array;
  constructor(private modalRef: MatDialogRef<LogsModalComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    this.data = data;
  }

  ngOnInit() {
  }

  // Método para actualizar los datos del modal
  updateData(newData) {
    console.log(newData)
    this.data = newData;
  }

  confirm() {
    if (this.data.onConfirm) {
      this.data.onConfirm(this.data.id); // Pasar el ID a la función onConfirm
    }

    this.modalRef.close();
  }

  cancel() {
    this.modalRef.close();
  }
}
