import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEspE from '@angular/common/locales/es-PE';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalCredentialsComponent } from './modals/modal-credentials/modal-credentials.component';
import { AlertModalComponent } from './modals/alert-modal/alert-modal.component';
import { CostumerListComponent } from './components/costumer-list/costumer-list.component';
import { ErrorAlertComponent } from './error-alert/error-alert/error-alert.component';
import { WarningComponent } from './modals/warning/warning.component';
import { LogsModalComponent } from './modals/logs-modal/logs-modal.component';
import { FormViewerComponent } from './components/form-viewer/form-viewer.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core'; // Importa este módulo
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

registerLocaleData(localeEspE, 'es-PE');

const MODULES = [
  MaterialModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  NgbModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatButtonToggleModule
];

const COMPONENTS = [
  ModalCredentialsComponent,
  AlertModalComponent,
  CostumerListComponent,
  ErrorAlertComponent,
  WarningComponent,
  LogsModalComponent,
  FormViewerComponent,
]

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [...MODULES],
  exports: [...MODULES, ...COMPONENTS],
})
export class SharedModule { }
