import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { LocalStorageService } from '@core/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const role: string = this.localStorageService.getItem('user').codeProfile;
    const allowedRoles: Record<string, string[]> = {
      SA: [
        'dashboard',
        'Nuevo-cliente',
        'Reportes-reconocimientos',
        'Nuevo-usuario-admin',
        'Credenciale-usuario',
        'Avances',
        'Pedido-sugerido',
        'Gestion-vendedores',
        'Producto-sugerido',
        'Precios-y-promociones',
        'Reporte-aliados',
        'Clientes-aliados',
        'reportes',
        'notificaciones',
        'fotos-productos',
        'banner',
        'cc-report',
        'hobs',
        'mas',
        'Alicorp-way',
      ],
      AD: [
        'dashboard',
        'Nuevo-cliente',
        'Reportes-reconocimientos',
        'Credenciale-usuario',
        'Avances',
        'Pedido-sugerido',
        'Gestion-vendedores',
        'Producto-sugerido',
        'Precios-y-promociones',
        'Reporte-aliados',
        'Clientes-aliados',
        'reportes',
        'notificaciones',
        'fotos-productos',
        'banner',
        'cc-report',
        'hobs',
        'mas',
        'Alicorp-way',
      ],
      RR: [
        'Avances',
        'Reportes-reconocimientos',
        'Reporte-aliados',
        'Pedido-sugerido',
        'Precios-y-promociones',
        'reportes',
        'Alicorp-way',
      ],
      RK: [
        'Precios-y-promociones',
        'Reportes-reconocimientos',
        'Reporte-aliados',
        'fotos-productos',
        'banner',
        'Alicorp-way',
      ],
    };

    const allowedUrls = allowedRoles[role];
    const url = route.routeConfig?.path;
    if (allowedUrls && url && allowedUrls.includes(url)) {
      return true;
    }

    // Redireccionar al URL específico para cada rol
    if (role === 'AD' || role === 'SO') {
      this.router.navigateByUrl('/admin/dashboard');
    } else if (role === 'RR') {
      this.router.navigateByUrl('/admin/Avances');
    } else if (role === 'RK') {
      this.router.navigateByUrl('/admin/Precios-y-promociones');
    }

    return false;
  }
}
