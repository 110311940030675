import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSelect } from '@angular/material/select';
import { DomSanitizer } from '@angular/platform-browser';

export interface Question {
  id: number;
  type: string; // 'datepicker', 'textArea', 'radioButton', 'checklist', 'optionalTextArea', 'uploadFile', 'combobox', 'listView'
  descriptionGeneral: string;
  options?: string[]; // Solo si es necesario
  titleQuestion?: string;
  description?: string;
}

@Component({
  selector: 'app-form-viewer',
  templateUrl: './form-viewer.component.html',
  styleUrls: ['./form-viewer.component.scss']
})
export class FormViewerComponent implements OnInit,AfterViewInit {
  @Input() questions: Question[] = [];
  @Input() formData: any = {};
  @ViewChild('matSelect') matSelect: MatSelect;
  favoriteSeason: string;
  seasons: string[] = ['Winter', 'Spring', 'Summer', 'Autumn'];
  currentQuestionIndex: number = 0; // Índice de la pregunta actual
  sectionNumber: number = 1; // Número de la sección actual

  constructor(   private matIconRegistry: MatIconRegistry,private domSanitizer: DomSanitizer,) {
    this.matIconRegistry.addSvgIcon(
      `datepicker_icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/date-picker-icon.svg'
      )
    );
  }

  ngOnInit() {
    this.updateSectionNumber(); 
  }
  ngAfterViewInit() {
    this.openSelect();
  }
  nextQuestion() {
    if (this.currentQuestionIndex < this.questions.length - 1) {
      this.currentQuestionIndex++;
      this.updateSectionNumber(); // Actualiza el número de sección al avanzar
    }
  }

  previousQuestion() {
    if (this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
      this.updateSectionNumber(); // Actualiza el número de sección al retroceder
    }
  }

  updateSectionNumber() {
    // Actualiza el número de sección basado en el índice actual
    this.sectionNumber = this.currentQuestionIndex + 1; // Sección basada en el índice actual (1-indexed)
  }

  get totalSections(): number {
    return this.questions.length; // Total de preguntas como el total de secciones
  }

  onFileChange(event: any, questionId: number) {
    const file = event.target.files[0];
    this.formData[questionId] = file; // Guarda el archivo en formData
  }

  formatSectionNumber(section: number): string {
    return section < 10 ? '0' + section : '' + section;
  }

  formatTotalSections(total: number): string {
    return total < 10 ? '0' + total : '' + total; // Formato para dos dígitos
  }

  onSubmit() {
    console.log(this.formData);
    // Aquí puedes agregar la lógica para enviar los datos a tu backend.
  }
  onCheckboxChange(option: string) {
    const currentQuestionId = this.questions[this.currentQuestionIndex].id;
  
    // Inicializa el formData si no existe
    if (!this.formData[currentQuestionId]) {
      this.formData[currentQuestionId] = {
        selectedOptions: {},
        descriptions: {}
      };
    }
  
    // Marca o desmarca la opción
    this.formData[currentQuestionId].selectedOptions[option] = !this.formData[currentQuestionId].selectedOptions[option];
  
    // Si se selecciona, inicializa la descripción
    if (this.formData[currentQuestionId].selectedOptions[option]) {
      this.formData[currentQuestionId].descriptions[option] = '';
    } else {
      delete this.formData[currentQuestionId].descriptions[option]; // Elimina la descripción si se desmarca
    }
  }
  openSelect() {
    setTimeout(() => {
      if (this.matSelect) {
        this.matSelect.open();
      } else {
        console.warn('matSelect is undefined');
      }
    }, 100);
    }
}
