<div class="container-modal" [ngClass]="dataRecieved.status == 2 ? 'max-size':''">
  <div class="img-modal">
    <img [ngClass]="dataRecieved.status == 8 ? 'check' :''" [src]="modals[dataRecieved.status].img" alt="">
  </div>
  <div class="message">
    <h1>{{modals[dataRecieved.status].title}}</h1>
    <p [innerHTML]="modals[dataRecieved.status].description"></p>
  </div>
  <div *ngIf="dataRecieved.status == 2" class="aditional-info">
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-alert-modal">
        <!-- Position Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="col-1"  >Estado</th>
          <td mat-cell *matCellDef="let element" class="red-alert"> 
            <p [ngClass]="element.error.type != 6 ? '' :'circle' ">{{element.error.type != 6 ? 'ERROR LÍNEA ' + element.position : 'NO DISPONIBLE'}}</p>
          </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="sku">
          <th mat-header-cell *matHeaderCellDef class="col-2"> SKU </th>
          <td mat-cell *matCellDef="let element"> {{element.sku || element[3] }} </td>
        </ng-container>
        <!-- Weight Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef class="col-3"> Descripción </th>
          <td mat-cell *matCellDef="let element" class="text-message"> 
            <p [innerHTML]="(element.error.type != 6 ?transform(element.error.message) : element.description)"></p>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
  <div class="buttons">
    <button *ngFor="let action of modals[dataRecieved.status].actions" class="fbx-button" [ngClass]="action.class"  (click)="action.event()">{{action.name}}</button>
  </div>
</div>
