import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from '@core/guards/login.guard.service';
import { MainGuard } from '@core/guards/main.guard.service';
import { LoginComponent } from './feature/login/login.component';

const routes: Routes = [
  {
    canActivate: [LoginGuard],
    path: '', component: LoginComponent
  },
  {
    canActivate: [MainGuard],
    path: 'admin', loadChildren: () => import('./feature/main/main.module').then(m => m.MainModule)
  },
  { path: '**', pathMatch: 'full', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
