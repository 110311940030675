import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClienteService } from '../http-cliente.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  private url = environment.apiURL;
  constructor(
    private _http: HttpClienteService,
    ) { }

  getProductsWithPaginator(data = {sku: '', page: 1, pageSize: 20}){
    const query = `${this.url}/wcl-material/bo-product-paginate?sku=${data.sku}&page=${data.page}&pageSize=${data.pageSize}`;
    return this._http.get(query);
  }
  getProducts(){
    const query = `${this.url}/wcl-material/products-get-all`;
    return this._http.get(query);
  }
  updateProduct(data){
    const query = `${this.url}/wcl-material/bo-product-update`;
    return this._http.post(query, data);
    
  }

// list notificacion
// https://skynet-api-dev.azure-api.net/api/wcl-customer/bo-paginate-notification?title=0&page=1&pageSize=5
  
}
