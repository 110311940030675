<div class="content-login">
  <div class="login-card row">
    <div class="col-12 col-md-6">
      <div class="login-main">
        <div>
          <div class="center">
            <a class="logo" routerLink='/'>
              <img src="assets/images/login/logo_alicorp.svg" width="155" height="45" alt="Alicorp" />
            </a>
          </div>
          
          <div  class="alert-danger pv-12 ph-16 mb-12 mt-24 fts-14" *ngIf="logInError">
            <span>El usuario o la contraseña que has introducido no es correcta. Intenta de nuevo por favor.</span>
          </div>
          <form class="theme-form" [formGroup]="loginForm">
            <p>Inicie sesión para mantenerse conectado.</p>
            <div class="form-group mb-12">
              <label class="col-form-label">Usuario</label>
              <input class="form-control" type="username" required="" placeholder="app@username.com"
                [ngClass]="loginForm.get('email')?.hasError('minlength') || loginForm.get('email')?.hasError('maxlength') ? 'error': '' "
                formControlName="email" autocomplete="off" maxlength="50">
              <div *ngIf="loginForm.get('email')?.touched && loginForm.get('email')?.hasError('required')"
                class="text text-danger mt-1">
                Email es requerido
              </div>
            </div>
            <div class="form-group mb-12">
              <label class="col-form-label">Contraseña</label>
              <div class="input-group">
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required="" maxlength="25"
                [ngClass]="loginForm.get('password')?.hasError('minlength') || loginForm.get('password')?.hasError('maxlength') ? 'error': '' "
                placeholder="*********" autocomplete="off">
                <span class="input-group-text" (click)="showPassword()">
                  <img [ngClass]="show ? 'icon-visibility-off' : 'icon-visibility'"
                  [src]="show ? '/assets/images/login/icon-eye.svg' : '/assets/images/login/icon-eye-close.svg'">
                </span>
              </div>
              <div *ngIf="loginForm.get('password')?.touched && loginForm.get('password')?.hasError('required')"
                class="text text-danger mt-1">
                Contraseña es requerida
              </div>
            </div>
            <div class="form-group mb-0 ta-c">
              <div class="col-lg-12 d-flex jc-sb mv-16">
                <!-- <div class="form-check mr-16">
                  <input type="checkbox" class="form-check-input" id="customCheck1">
                  <label class="form-check-label" for="customCheck1">Recuérdame</label>
                </div>
                <a href="recoverpw.html" class="recover">¿Has olvidado tu contraseña?</a> -->
              </div>
              <button *ngIf="!loading" class="btn btn-secondary ph-32 btn-login" (click)="login()" type="button">
                <span>INGRESAR</span>
              </button>
              <button *ngIf="loading" class="btn btn-secondary ph-32 btn-login" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Cargando...
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 img-login">
      <p class="welcome">
        <span>¡Hola!</span> Bienvenido a una nueva manera de administrar
      </p>
    </div>
  </div>
</div>