import { Injectable } from '@angular/core';
import { Router, CanActivate, Route, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '@core/services/auth/auth.service';

@Injectable()
export class LoginGuard implements CanActivate {

  permiso: any = {};

  constructor(
    private router: Router,
    private _authService: AuthService,
  ) {
  }

  canActivate(_route: ActivatedRouteSnapshot): boolean {
    if (!this._authService.isLoggedIn()) {
      return true;
    } else {
      this.router.navigate(['/admin']);
      return false;
    }
  }
}
