<div class="mh-32 overflow-hidden">
  <div class="d-flex jc-fe mv-32">
    <!-- <button
        class="btn ph-32 btn-run ml-24 d-flex color-login-text"
        type="button"
      >
        <mat-icon class="mr-8">cloud_download</mat-icon>
        Descargar Clientes
      </button> -->
  </div>
  <div class="content-filter jc-sb">
    <div class="dataTables_length">
      <label class="d-flex ai-center w-space color-login-text font-book fts-14"
        >Cantidad por página
        <select
          aria-controls="example"
          class="form-select form-select-sm ml-8"
          [(ngModel)]="pageSize"
          (ngModelChange)="refreshCountries()"
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="200">200</option>
        </select>
      </label>
    </div>
    <div id="example_filter" class="dataTables_filter">
      <label class="d-flex ai-center color-login-text font-book fts-14"
        >Buscar:
        <input
          type="search"
          class="form-control form-control-sm ml-8"
          [(ngModel)]="characterSearch"
          (ngModelChange)="searchList($event)"
          maxlength="50"
      /></label>
    </div>
  </div>
  <div class="mv-32 overflow-auto">
    <table class="table">
      <thead class="">
        <tr>
          <th scope="col ta-c" (sort)="onSort($event)">Código</th>
          <th scope="col ta-s" (sort)="onSort($event)">
            Razon Social
          </th>
          <th scope="col ta-c">Aliado</th>
          <th scope="col ta-c">Recoge</th>
          <th scope="col ta-c">Reporte</th>
          <th scope="col ta-c">Avances</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let country of listSpecialUserShow;let i = index">
          <!-- DATA DIFERENTE DE NULL -->
          <td scope="row" class="ta-c">{{ country.code }}</td>
          <td class="ta-s">{{ country.name }}</td>
          <td class="ta-c">
            <input
              class="form-check-input"
              type="checkbox"
              [checked]="country.ally"
              (change)="onChangeCheckbox(country?.ally,'ally',i,country)"
            />
          </td>
          <td class="ta-c">
            <div class="d-flex justify-content-center">
              <mat-slide-toggle
                #slide
                (change)="onChange(country.pickUp,country.code,slide,country.pickUpStatus)"
                [checked]="country.pickUpStatus"
                >Activar
              </mat-slide-toggle>
            </div>
          </td>
          <td class="ta-c">
            <input
              class="form-check-input"
              type="checkbox"
              [checked]="country.benefit"
              (change)="onChangeCheckbox(country.benefit,'benefit',i,country)"
            />
          </td>
          <td class="ta-c">
            <input
              class="form-check-input"
              type="checkbox"
              [checked]="country.advance"
              (change)="onChangeCheckbox(country.advance,'advance',i,country)"
            />
          </td>
        </tr>
        <ng-container *ngIf="loading">
          <tr *ngFor="let number of counter(pageSize)">
            <td scope="row" class="ta-c td-padding">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                class="mb-0"
              ></ngx-skeleton-loader>
            </td>
            <td class="ta-c td-padding">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
              ></ngx-skeleton-loader>
            </td>
            <td class="ta-c td-padding">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
              ></ngx-skeleton-loader>
            </td>
            <td class="ta-c td-padding">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
              ></ngx-skeleton-loader>
            </td>
            <td class="ta-c td-padding">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
              ></ngx-skeleton-loader>
            </td>
            <td class="ta-c td-padding">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
              ></ngx-skeleton-loader>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <div
      class="show-box-shadow pv-10 ph-16 ai-center ta-c jc-center mb-18 font-book"
      *ngIf="!loading && collectionSize === 0 && !errorListSpecialUser"
    >
      <span class="fts-12">No existe registros del cliente</span>
    </div>
    <div
      class="show-box-shadow pv-10 ph-16 ai-center ta-c jc-center mb-18 font-book color-secondary"
      *ngIf="!loading && errorListSpecialUser"
    >
      <span class="fts-12">No existe cliente.</span>
    </div>
    <div
      class="content-count jc-sb ai-center pv-16"
      *ngIf="errorListSpecialUser == false"
    >
      <div class="dataTables_length font-book">
        Total
        <span class="fontw-500">{{collectionSize}}</span> registros
      </div>
      <div>
        <ngb-pagination
          [collectionSize]="collectionSize"
          [(page)]="page"
          [pageSize]="pageSize"
          [maxSize]="5"
          (pageChange)="refreshCountries()"
        >
          <ng-template ngbPaginationPrevious>Previous</ng-template>
          <ng-template ngbPaginationNext>Next</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>
