<div mat-dialog-content class="content-warning">
  <div class="ta-c">
    <img src="assets/images/modals/icon-file-success.svg" alt="">
    <div class="pt-16 pb-16 fts-20 font-medium">El archivo del producto sugerido se subió con éxito.</div>
  </div>
  <div class="warning-alert mb-16 p-16">
    <p class="m-0 fts-14 pb-24 sku-warning font-medium">Se presentaron los siguientes errores</p>
    <ul class="sku-warning pb-6" *ngIf="duplicateData[0]?.skus && duplicateData[0]?.skus.length>0">
      <li class="fts-14 font-medium m-0 fontw-300"> No se encontraron los siguientes SKUs:</li>
      <span *ngFor="let item of duplicateData[0]?.skus; let i = last" class="font-medium fts-12">
        {{item.sku}}{{i ? '' : ','}}&nbsp;
      </span>.
    </ul>
    <ul class="sku-warning" *ngIf="duplicateData[1]?.duplicates && duplicateData[1]?.duplicates.length>0">
      <li class="fts-14 font-medium m-0">Se encontraron los siguientes SKUs duplicados:</li>
      <span *ngFor="let item of duplicateData[1]?.duplicates;let i = last" class="font-medium fts-12">
        {{item.sku}}{{i ? '' : ','}}&nbsp;
      </span>.
    </ul>
  </div>
</div>
<div mat-dialog-actions class="d-flex jc-center mt-0">
  <button class="btn-green btn-primary ph-32 mt-0 mb-16" (click)="confirm()">Entiendo</button>
</div>