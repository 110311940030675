import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClienteService } from '../http-cliente.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private url = environment.apiURL;
  constructor(
    private _http: HttpClienteService,
    ) { }

  saveNotification(data: any){
    const query = `${this.url}/wcl-customer/bo-save-notification`;
    return this._http.post(query, data);
  }

  getNotifications(data = {title: '', page: 1, pageSize: 20}){
    const query = `${this.url}/wcl-customer/bo-paginate-notification?title=${data.title}&page=${data.page}&pageSize=${data.pageSize}`;
    return this._http.get(query);
  }

  deleteNotification(data: any){
    const query = `${this.url}/wcl-customer/bo-delete-notification`;
    return this._http.post(query, data);
  }

// list notificacion
// https://skynet-api-dev.azure-api.net/api/wcl-customer/bo-paginate-notification?title=0&page=1&pageSize=5
  
}
