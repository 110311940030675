import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})

export class UtilsService {

  constructor(public _snackBar: MatSnackBar) { }
  //convertir a base 64
  static readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    })
  }

  snackBarUpload(message, panelClassName) {
    return this._snackBar.open(message, 'OK', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [panelClassName],
      duration: 5000,
    });
  }

  // Función que formatea numeros
  static formatNumber(n) {
    var numberFormat = new Intl.NumberFormat('en-US');
    var formatted = (numberFormat.format(n));
    return formatted.toString();
  }

  // Función que formatea numeros de forma exacta
  static formatTotalNumber(n) {
    var numberFormat = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    var formatted = (numberFormat.format(n));
    return formatted.toString();
  }

  //Función para ordenamiento 
  compare_lname(a, b) {
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  }
  
  //Formato de files para la subida de archivos
  formatBytes(bytes?: any, decimals?: any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}

