import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClienteService } from '../http-cliente.service';

@Injectable({
  providedIn: 'root'
})
export class UploadReportsService {

  private url = environment.apiURL;
  constructor(
    private _http: HttpClienteService,
    ) { }

  getReports(name: string){
    const query = `${this.url}/wcl-customer/reports-path?path=${name}`;
    return this._http.get(query);
  }
  loadReports(data: any){
    const query = `${this.url}/wcl-customer/upload-report`;
    return this._http.post(query, data);
  }
  getGeneralReports(type: number){
    const param = type === 1 ? 'recognition-list' : 'sales-progress-list';
    const query = `${this.url}/wcl-document/${param}`;
    return this._http.get(query);
  }
  deleteGeneralReports(data: any, type: number) {
    const param = type === 1 ? 'recognition-delete' : 'sales-progress-delete';
    const query = `${this.url}/wcl-document/${param}`;
    return this._http.delete(query, { body: data });
  }
  loadGeneralReports(data: any, type: number){
    const param = type === 1 ? 'upload-recognitions' : 'upload-sales-progress';
    const query = `${this.url}/wcl-document/${param}`;
    return this._http.post(query, data);
  }
  getSalesProgressLogs(id: number){
    const query = `${this.url}/wcl-document/file-versions?id=${id}`;
    return this._http.get(query);
  }
}
