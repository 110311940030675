import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss']
})
export class WarningComponent implements OnInit {

  duplicateData;

  constructor(private modalRef: MatDialogRef<WarningComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.duplicateData = data.res;
  }

  ngOnInit() {
  }

  confirm() {
    this.modalRef.close();
  }
  cancel() {
    this.modalRef.close();
  }
}
