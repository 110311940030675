<section class="show-box-shadow" *ngIf="questions">
  <div class="container-form">
    <!-- Encabezado con logotipos -->
    <div class="d-flex jc-sb ai-c ph-24 pv-12">
      <img src="assets/images/login/logo_alicorp.svg" alt="Logo Alicorp" />
      <img src="assets/images/user.svg" alt="Icono de usuario" />
    </div>

    <hr class="dividing-line-solid mb-0 mt-0" />

    <!-- Título y descripción del formulario -->
    <div class="ph-24 pv-16">
      <div class="d-flex ai-center fts-16 fontw-600 title-viewer">
        <mat-icon>keyboard_backspace</mat-icon>
        <span class="ml-8">Rutinas</span>
      </div>
      <div class="mt-16">
        {{questions[currentQuestionIndex]?.descriptionGeneral}}
      </div>
    </div>

    <hr class="dividing-line-solid mb-16 mt-0 mh-24" />

    <!-- Sección de preguntas -->
    <div class="mh-24">
      <div class="fts-24 fontw-700 color-neutral-medium">
        <span class="color-neutral-darkest">{{ formatSectionNumber(sectionNumber) }}</span>
        / <span>{{ formatTotalSections(totalSections) }}</span>
      </div>
      <div class="fts-18 fontw-700 color-neutral-darkest mb-16">
        {{questions[currentQuestionIndex]?.titleQuestion}}
      </div>
      <div class="fts-16 fontw-400 color-neutral-dark mb-16">
        {{questions[currentQuestionIndex]?.description}}
      </div>
      <div class="container-body pb-3">
        <ng-container *ngIf="questions.length > 0">
          <!-- Componente para renderizar la pregunta -->
          <ng-container [ngSwitch]="questions[currentQuestionIndex]?.type">
            <!-- Campo de fecha -->
            <mat-form-field appearance="outline" *ngSwitchCase="'datepicker'" class="w-100">
              <mat-label>Seleccione una fecha</mat-label>
              <input matInput [matDatepicker]="picker" [(ngModel)]="formData[questions[currentQuestionIndex]?.id]"
                name="question{{ questions[currentQuestionIndex]?.id }}" [disabled]="true" />
              <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon [svgIcon]="'datepicker_icon'"></mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>


            <!-- Área de texto -->
            <textarea *ngSwitchCase="'textArea'" [(ngModel)]="formData[questions[currentQuestionIndex].id]"
              [disabled]="true" name="question{{ questions[currentQuestionIndex].id }}"
              class="textArea w-100">{{questions[currentQuestionIndex].options}}</textarea>

            <!-- Grupo de botones de opción -->
            <div *ngSwitchCase="'radioButton'">
              <mat-form-field appearance="outline" class="w-100">
                <button mat-icon-button matPrefix>
                  <mat-icon class="color-search">search</mat-icon>
                </button>
                <mat-label>Busca en la lista.</mat-label>
                <input matInput placeholder="Busca en la lista." [disabled]="true" />
              </mat-form-field>

              <mat-radio-group [(ngModel)]="formData[questions[currentQuestionIndex].id]"
                name="question{{ questions[currentQuestionIndex].id }}">
                <div *ngFor="let option of questions[currentQuestionIndex].options" class="custom-radio mb-8">
                  <mat-radio-button [value]="option" class="radio-button-custom" [disabled]="true">
                    {{ option }}
                  </mat-radio-button>
                </div>
              </mat-radio-group>
            </div>

            <!-- Lista de verificación -->
            <div *ngSwitchCase="'checklist'">
              <mat-form-field appearance="outline" class="w-100">
                <button mat-icon-button matPrefix>
                  <mat-icon class="color-search">search</mat-icon>
                </button>
                <mat-label>Busca en la lista.</mat-label>
                <input matInput placeholder="Busca en la lista." [disabled]="true" />
              </mat-form-field>
              <div *ngFor="let option of questions[currentQuestionIndex].options">
                <mat-checkbox [disabled]="true" class="custom-checkbox mb-8">
                  {{ option }}
                </mat-checkbox>
              </div>
            </div>

            <!-- Área de texto opcional -->
            <div *ngSwitchCase="'optionalTextArea'">
              <div *ngFor="let option of questions[currentQuestionIndex].options;let i = index">
                <div class="custom-optionalTextArea mb-8">
                  <mat-checkbox (change)="onCheckboxChange(option)" [disabled]="true" [checked]="i === 0" class="fts-14
                  fontw-400 line-height-24">
                    {{ option }}
                  </mat-checkbox>
                  <div class="mt-8 fts-14" *ngIf="i === 0">
                    <textarea [disabled]="true" name="question{{ questions[currentQuestionIndex].id }}"
                      class="textArea w-100 fts-14">Describe tu repuesta aquí.</textarea>
                  </div>
                </div>
              </div>
            </div>

            <!-- Carga de archivos -->
            <div *ngSwitchCase="'uploadFile'" class="ta-c">
              <div class="dashed-custom">
                <div class="d-flex jc-center ai-c container-img">
                  <img src="assets/images/upload_regular.svg" alt="Icono de carga" width="24" height="24">
                </div>
                <div class="fts-16 fontw-400 mt-8"><span class="text-select">Selecciona</span> o arrastra tu archivo
                </div>
                <div class="fts-12 fontw-400">Peso máx 10 MB</div>
              </div>
            </div>
            <!-- Desplegable -->
            <div *ngSwitchCase="'combobox'">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Elige una opción.</mat-label>
                <mat-select #matSelect [(ngModel)]="formData[questions[currentQuestionIndex].id]" disableOptionCentering
                  [disabled]="true" (opened)="true">
                </mat-select>
                <mat-icon matSuffix class="color-search">keyboard_arrow_up</mat-icon>
              </mat-form-field>
             <div class="custom-combobox">
              <mat-form-field appearance="outline" class="w-100">
                <button mat-icon-button matPrefix>
                  <mat-icon class="color-search">search</mat-icon>
                </button>
                <mat-label>Busca en la lista.</mat-label>
                <input matInput placeholder="Busca en la lista." [disabled]="true" />
              </mat-form-field>
              <mat-option *ngFor="let option of questions[currentQuestionIndex].options" [value]="option">
                {{ option }}
              </mat-option>
             </div>
            </div>


            <!-- Vista de lista -->
            <div *ngSwitchCase="'listView'">
              <div class="d-flex jc-sb container-listView">
                <div *ngFor="let num of [1, 2, 3, 4, 5]" (click)="formData[questions[currentQuestionIndex].id] = num"
                  class="button-listView">
                  {{ num }}
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- Contenedor de botones de navegación -->
  <div class="button-container">
    <hr class="dividing-line-solid mb-0 mt-0" />
    <div class="d-flex jc-sb ai-c p-24">
      <button type="button" class="btn btn-secondary fts-14 fontw-600 btn-arrow_back d-flex ai-center"
        (click)="previousQuestion()" [disabled]="currentQuestionIndex === 0">
        <mat-icon class="fts-16 d-flex ai-center">arrow_back</mat-icon>
        <span class="ml-8">Atrás</span>
      </button>
      <button type="button" class="btn btn-primary fts-14 fontw-600 btn-arrow_forward d-flex ai-center jc-center"
        (click)="nextQuestion()" [disabled]="currentQuestionIndex >= questions.length - 1">
        <span class="ml-8">Siguiente</span>
        <mat-icon class="fts-16 d-flex ai-center">arrow_forward</mat-icon>
      </button>
    </div>
  </div>
</section>